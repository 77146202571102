@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap");

:root {
    --blue-bug: #292d3d;
    --little-swift: #487e8e;
    --mild-java: #60a396;
    --green-python: #71bd9f;
    --io: #e6e7e8;
    --angular-yellow: #f7da63;
    --analog-black: #2c2c2c;
    --hacker-gray: #636466;
}

@font-face {
    font-family: "Gilroy";
    src: url("./fonts/gilroy-extrabold.otf") format("opentype")
            font-weight-normal,
        url("./fonts/gilroy-light.otf") format("opentype") font-weight-lighter;
}

@font-face {
    font-family: "Quicksand";
    src: url("./fonts/Quicksand-Regular.otf") format("opentype")
            font-weight-normal,
        url("./fonts/Quicksand-LightItalic.otf") format("opentype")
            font-weight-lighter font-style-italic,
        url("./fonts/Quicksand-Light.otf") format("opentype")
            font-weight-lighter,
        url("./fonts/Quicksand-Italic.otf") format("opentype")
            font-weight-normal font-style-italic,
        url("./fonts/Quicksand-BoldItalic.otf") format("opentype")
            font-weight-bolder font-style-italic,
        url("./fonts/Quicksand-Bold.otf") format("opentype") font-weight-bolder;
}
